import { createI18n } from 'vue-i18n'
import axios from 'axios'

/*
 *
 * !!! Special Characters in json
 * The following characters used in the message format syntax are processed by the compiler as special characters:
 * {, }, @, $, |
 * If you want to use these characters, you will need to use the Literal interpolation (https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation)
 * Example:
 * { address: "{account}{'@'}{domain}" }
 * 
 */

function getLocales() {
  const locales = require.context(
    "./",
    false,
    /[a-z0-9_]+\.json$/i
  );
  return locales;
}

function loadLocaleMessages($url = "files", $lang = "ru") {
  const locales = getLocales();
  const messages = {};

  if($url == "files"){
    locales.keys().forEach(key => {
      const matched = key.match(/([a-z]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
  } else {
    var outerLocales = {};
    let request = axios.get($url)
    .then(function (response) {
      locales.keys().forEach(key => {
        const locale = key.match(/([a-z]+)\./i)[1];
        if (response.data[locale]) {
          messages[locale] = response.data[locale];
        }
      });
    })
    .catch(function (error) {
      console.error('cant load strings');
    });
  }

  return messages;
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "za",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(process.env.VUE_APP_I18N_URL || "files"),
})

export default i18n
