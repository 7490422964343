<template>
  <SiteHeader />
    
  <div class="entrance">
    <AuthReg />
    {{ $t('entrance_h1') }}

  </div>
</template>

<script>
// @ is an alias to /src
import AuthReg from '@/components/AuthReg.vue'

export default {
  name: 'Login',
  components: {
    AuthReg
  }
}
</script>
