<template>
  <h1>{{ userLogin }}</h1>
</template>

<script>
export default {
  name: '',
  props: {
    userLogin: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
